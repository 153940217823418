import React from 'react';
import { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import { rowFullHeight } from '../styles/row.css';

type RowProps = {
  children: ReactNode;
  className: string;
};

const rowClasses = 'g-0 ' + rowFullHeight;
export default function KDRow({ children, className }: RowProps) {
  return <Row className={rowClasses + ' ' + className}> {children} </Row>;
}
