import { Col, Container, Row } from 'react-bootstrap';

import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { ReactNode } from 'react';
import { rowFullHeight } from '../styles/row.css';

type TitleProps = {
  children: ReactNode;
};

export default function Title({ children }: TitleProps) {
  return (
    <Row>
      <Col>
        <Container>
          <Row className={rowFullHeight}>
            <div className="d-flex justify-content-center align-self-center m-">
              <h1 className={PrimaryFont}> {children} </h1>
            </div>
          </Row>
        </Container>
      </Col>
    </Row>
  );
}
