import * as React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import KDContainer from '../components/Container';
import KDRow from '../components/Row';
import Title from '../components/Title';

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <main>
      <KDContainer>
        <KDRow className="">
          <Title>404</Title>
        </KDRow>
      </KDContainer>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
