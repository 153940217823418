import { Container } from 'react-bootstrap';
import KDMakeClasses from '../utilities/common';
import React from 'react';
import { ReactNode } from 'react';
import { backgroundSecondary } from '../styles/background.css';

interface ContainerProps {
  children?: ReactNode;
}

const containerClasses = KDMakeClasses(['g-0', backgroundSecondary]);

const KDContainer: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Container className={containerClasses} fluid>
      {' '}
      {children}{' '}
    </Container>
  );
};

export default KDContainer;
